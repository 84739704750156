<template>
  <div class="unload-content__cargo">
    <r-block
      v-for="item in cargo.cargoes"
      :key="item.id"
      background="containers"
      radius
    >
      <r-block
        no-padding
        col
        space-between
      >
        <r-text>
          {{ item.packing_list_no }}
        </r-text>
        <r-button
          icon="info"
          type="secondary"
          mini
          @click="openInfo(item)"
        />
      </r-block>
      <r-text
        v-if="!item.cargo_places.length"
        type="caption"
      >
        Нет погрузочных мест
      </r-text>
      <template v-else>
        <r-block
          v-for="(cp, i) in item.cargo_places"
          :key="cp.id"
          no-padding
          space-between
          col
        >
          <r-block
            col
            start
            no-padding
          >
            <r-icon
              name="storehouse"
              :size="21"
            />
            <r-text>
              {{ `Грузовое место ${i + 1}` }}
            </r-text>
          </r-block>
          <ritm-select
            v-model="cp.vehicle_id"
            :options="vehiclesList"
            label-field="reg_number"
          />
          <r-date-picker
            v-model="cp.receiving_date_fact"
            :disabled="!cp.vehicle_id"
            clearable
            type="date"
            class="r-date-picker"
            format="dd.MM.yyyy"
            placeholder="Выберите дату"
          />
        </r-block>
      </template>
    </r-block>
    <unload-cargo-info
      v-if="activeCargo"
      :is-visible="showInfo"
      :cargo="activeCargo"
      @close="closeInfo"
    />
  </div>
</template>

<script>
// import RitmSelect from '@/components/ritm-select/ritm-select.vue'

export default {
  components: {
    RitmSelect: () => import('@/components/ritm-select/ritm-select.vue'),
    unloadCargoInfo: () => import('./unload-cargo-info.vue')
  },
  props: {
    vehiclesList: {
      type: Array,
      required: true
    },
    cargo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showInfo: false,
      activeCargo: null
    }
  },
  methods: {
    openInfo(item) {
      this.showInfo = true
      this.activeCargo = item
    },
    closeInfo() {
      this.showInfo = false
      this.activeCargo = null
    }
  }
}
</script>

<style lang="scss">
.unload-content__cargo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;

  .r-date-picker {
    width: 164px;
  }
}
</style>
